<template>
  <div class="cfa-root">
    <c-f-a-nav :alert="alertString" contentClass="position-relative">
      <template v-slot:title>
        <div class="w-100 pt-5 text-start mt-2 mb-3 d-flex justify-content-between align-items-center">
          <h1 class="cfa-section-title">MODULES</h1>
          <div>
            <button @click="this.newModule=true" class="btn cfa-btn-primary me-2">
              MODULES <i class="fa fa-plus">&nbsp;</i>
            </button>
            <button @click="this.newTags=true" class="btn cfa-btn-primary">
              TAGS <i class="fa fa-plus">&nbsp;</i>
            </button>
          </div>
        </div>
      </template>
      <template v-slot:content>
        <div v-show="!waitingAPIResponse" class="cfa-page">
          <div>
            <content-filter @viewMode="getViewMode" @viewFilter="getViewFilter" :total1="totalModule" :total2="totalInProgress"></content-filter>
          </div>
          <div v-if="this.allModules.length == 0" class="d-flex justify-content-center align-items-center" style="min-height: 70vh">
            <div class="d-flex flex-column">
              <img src="@/assets/img/svg/cfa-ud-assets-05.svg" alt="empty content" style="width: 15em;"/>
              <span class="mt-3 fs-5 fw-bold">Oups ! Rien pour le moment.</span>
            </div>
          </div>
          <div v-else>
            <div class="row pt-3">
              <course-card  v-for="module, i in this.allModules" v-bind:key="i" @update="getAllModule" :data="module" :tags="allTags" :id="module.id" :type="module.category" :published="(module.statut == 'available') ? true : false" :preview="module.module_image" :label="'Module '+(i+1)" :finished="(module.statut != 'available') ? false : false" :started="(i!=0) ? false : true" :title="module.title" :description="module.resume" :max__horaire="module.duration" :viewMode='displayMode' :filterMode="filterMode"  @publish="publishModule" @sync="getAllModule" :uid="'cfa-crud-module-'+i"></course-card>
            </div>
          </div>
        </div>
        <div v-show="waitingAPIResponse" class="cfa-page">
          <div class="d-flex justify-content-center align-items-center" style="min-height: 70vh">
            <div class="preloader_center d-flex flex-column">
              <img src="../../assets/img/gif/rocket.gif" alt="cfa preloader">
              <p class="pl-3">loading . . .</p>
            </div>
          </div>
        </div>
      </template>
    </c-f-a-nav>
    <!--footer-->
    <c-f-a-footer></c-f-a-footer>
    <!-- create tags -->
    <scalable-modal @callback="addNewTag" :break="exitTagModule" displayMode="flex" label="AJOUTER UN TAGS" uid="cfa-modal-tags-crud" @close="newTags=false" :open="newTags" :advancedControl="true" validationButtonText="Ajouter ce tags" exitButtonText="Annuler" :style="'width: 70%; height: auto; margin-top: 2em; margin-bottom: 2em; background-color: #f0f0f0;'">
      <template v-slot:content>
        <div class="row w-100 mt-3">
          <div class="form-group py-3 pe-0 pe-md-2 ">
            <p class="mb-2"><strong>TAGS EXISTANT</strong></p>
            <ul v-if="allTags.length != 0" class="tagUl d-flex flex-wrap">
              <li v-for="tag, i in allTags" v-bind:key="i">
                <tags-button :name="tag.name" :id="tag.id" @deletedTag="removeTag"></tags-button>
              </li>
            </ul>
            <ul v-else>
              <div class="d-flex justify-content-center w-100">
                <img width="55" src="@/assets/img/gif/load2.gif" alt="loader"/>
              </div>
            </ul>
          </div>
          <div class="form-group py-3 pe-0 pe-md-2 ">
            <p class="mb-2"><strong>TAGS</strong></p>
            <div class="d-flex flex-row">
              <input v-model="tag" class="form-control" type="text" placeholder="#PHISHING">
            </div>
          </div>
        </div>
      </template>
    </scalable-modal>
    <!-- create module -->
    <scalable-modal @callback="addNewModule" :break="exitModuleModal" label="AJOUTER UN MODULE" uid="cfa-modal-003" @close="newModule=false" :open="newModule" :advancedControl="true" validationButtonText="Créer ce module" exitButtonText="Annuler" :style="'width: 75%; height: auto; margin-top: 2em; margin-bottom: 2em; background-color: #f0f0f0;'">
      <template v-slot:content>
        <!-- blo1 -->
        <div class="row w-100">
          <div class="col-8">
            <!--<div class="mt-2">
              <modern-input @inputChange="(e)=>{this.connect_email = e}" placeholder="Votre Adresse email" errorText="Champ requis" uid="cfa-username-bloc" type="email" css></modern-input>
            </div>-->
            <div class="form-group py-2 pe-0 pe-md-2 ">
                <p class="mb-2"><strong>TITRE</strong></p>
                <div class="d-flex flex-row">
                  <input v-model="module_title" class="form-control" type="text" placeholder="SÉCURITÉ DU POST DE TRAVAIL">
                </div>
              </div>
            <div class="mt-2">
              <p class="mb-2"><strong>DESRIPTION</strong></p>
              <textarea v-model="module_description" class="descriptionArea"></textarea>
            </div>
          </div>
          <div class="col-4 pe-0">
            <div>
              <div class="form-group py-2 pe-0 pe-md-2 ">
                <p class="mb-2"><strong>CATÉGORIE</strong></p>
                <div class="d-flex flex-row">
                  <input v-model="module_category" class="form-control" type="text" placeholder="SÉCURITÉ">
                </div>
              </div>
              <div class="form-group py-3 pe-0 pe-md-2 ">
                <p class="mb-2"><strong>DURÉE</strong></p>
                <div class="d-flex flex-row">
                  <input v-model="module_time" class="form-control" type="text" placeholder="(minutes)">
                </div>
              </div>
              <div class="form-group py-3 pe-0 pe-md-2 ">
                <p class="mb-2"><strong>LANGUAGE</strong></p>
                <div class="d-flex flex-row">
                  <select v-model="module_lang" class="form-control" >
                    <option value="" default>Selectionner</option>
                    <option value="FRANÇAIS">FRANÇAIS</option>
                    <option value="ENGLISH">ANGLAIS</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row w-100 mt-3">
          <div class="form-group py-3 pe-0 pe-md-2 ">
            <p class="mb-2"><strong>IMAGE DE COUVERTURE</strong></p>
            <image-input @image-loaded="onImageLoaded"/>
          </div>
        </div>

        <div class="row w-100 mt-3">
          <div class="form-group py-3 pe-0 pe-md-2 ">
            <p class="mb-2"><strong>LIEN VIDÉO</strong></p>
            <div class="d-flex flex-row">
              <input v-model="module_video" class="form-control" type="text" placeholder="https://www.youtube...">
            </div>
          </div>
        </div>

        <div class="row w-100 mt-3">
          <div class="form-group py-3 pe-0 pe-md-2 ">
            <p class="mb-2"><strong>LIEN CONTENU</strong></p>
            <div class="d-flex flex-row">
              <input v-model="module_content" class="form-control" type="text" placeholder="https://iseazy.com...">
            </div>
          </div>
        </div>

        <div class="row w-100 mt-3">
          <div class="form-group py-3 pe-0 pe-md-2 ">
            <p class="mb-2"><strong>TAGS</strong></p>
            <div class="d-flex flex-row">
              <select v-model="module_tagSelect1" class="form-control me-2" >
                <option value="default" selected disabled hidden>Attach a tag</option>
                <option v-for="tag in allTags" v-bind:key="tag.id" :value="tag.id">{{ tag.name }}</option>
              </select>
              <select v-model="module_tagSelect2" class="form-control mx-2" >
                <option value="default" selected disabled hidden>Attach a tag</option>
                <option v-for="tag in allTags" v-bind:key="tag.id" :value="tag.id">{{ tag.name }}</option>
              </select>
              <select v-model="module_tagSelect3" class="form-control ms-2" >
                <option value="default" selected disabled hidden>Attach a tag</option>
                <option v-for="tag in allTags" v-bind:key="tag.id" :value="tag.id">{{ tag.name }}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="row w-100 mt-3">
          <div class="form-group py-3 pe-0 pe-md-2 ">
            <p class="mb-2"><strong>CIBLE</strong></p>
            <div class="d-flex flex-row mb-2">
              <input v-model="module_target" class="form-control" type="text">
            </div>
          </div>
        </div>
      </template>
    </scalable-modal>
  </div>
</template>

<script>
import CFAFooter from '@/components/nav/CFAFooter.vue'
import CFANav from '@/components/nav/CFANav.vue'
// import ContentFilter from '@/components/shared/ContentFilter.vue'
import CourseCard from '@/components/shared/course/CourseCard.vue'
import { getAPI } from '@/axios-api.js'
// import PreLoader from '@/components/shared/PreLoader.vue'
import ScalableModal from '@/components/shared/modal/ScalableModal.vue'
// import ModernInput from '@/components/shared/input/ModernInput.vue'
import ImageInput from '@/components/shared/input/ImageInput.vue'
// import { Translate } from '@google-cloud/translate';
import TagsButton from "@/components/shared/button/TagsButton.vue";

export default {
  name: 'DashboardView',
  components: {
    CFAFooter,
    CFANav,
    CourseCard,
    ScalableModal,
    ImageInput,
    TagsButton,
  },
  data () {
    return {
      allModules: [],
      allTags: [],
      alertString: null,
      displayMode: 'GROUP',
      filterMode: 'ALL',
      alertMe: false,
      gAlertMessage: '',
      gAlertType: '',
      waitingAPIResponse: false,
      newModule: false,
      newTags: false,
      tag: null,
      exitTagModule: null,
      exitModuleModal: null,
      module_tagSelect1: '',
      module_tagSelect2: '',
      module_tagSelect3: '',
      module_target: '',
      module_content: '',
      module_video: '',
      module_title: '',
      module_description: '',
      module_lang: '',
      module_time: '',
      module_category: '',
      module_image_cover: null,
      totalModule: 0,
      totalInProgress: 0,
    }
  },
  methods: {
    removeTag (e) {
      for (let i = 0; i < this.allTags.length; i++) {
        if (this.allTags[i].id === e) {
          this.allTags.splice(i, 1);
          break;
        }
      }
    },
    getViewMode (e) {
      this.displayMode = e
    },
    getViewFilter (e) {
      this.filterMode = e
    },
    publishModule (bool) {
      this.alertString = `Module ${(bool ? 'publié' : 'désactivé')} avec succès|alert-yes|4000::`+Date.now()
    },
    getAllModule () {
      this.waitingAPIResponse = true
      getAPI.get('modules/')
      .then(response => {
        if(response.status == 200) {
          let modulesData = response.data
          this.allModules = modulesData.results
          this.totalModule = modulesData.count
          // TODO: make this dynamic
          this.totalInProgress = 1
        }
        else {
          let msg = 'Oups ! something went wrong.'
          this.alertString = `${msg}|alert-no|5000::`+Date.now()
        }
        setTimeout(() => {
          this.waitingAPIResponse = false
        }, 2000);
      })
      .catch(error => {
        if (error.response) {
          let firstError = Object.values(error.response.data)[0][0]
          this.alertString = `${firstError}|alert-no|10000::`+Date.now()
        }
        else if (error.request) {
          let msg = 'The request was made but no response was received. Please check your network.'
          this.alertString = `${msg}|alert-no|8000::`+Date.now()
        }
        else {
          let msg = 'Oups ! something went wrong.'
          this.alertString = `${msg}|alert-no|5000::`+Date.now()
        }
        this.waitingAPIResponse = false
      })
    },
    getAllTabs () {
      getAPI.get('tags')
          .then(response => {
            if(response.status == 200) {
              this.allTags = response.data.results
            }
            else {
              let msg = 'Oups ! something went wrong.'
              this.alertString = `${msg}|alert-no|5000::`+Date.now()
            }
          })
          .catch(error => {
            if (error.response) {
              let firstError = Object.values(error.response.data)[0][0]
              this.alertString = `${firstError}|alert-no|10000::`+Date.now()
            }
            else if (error.request) {
              let msg = 'The request was made but no response was received. Please check your network.'
              this.alertString = `${msg}|alert-no|8000::`+Date.now()
            }
            else {
              let msg = 'Oups ! something went wrong.'
              this.alertString = `${msg}|alert-no|5000::`+Date.now()
            }
          })
    },
    addNewTag () {
      if(this.tag != null && this.tag.length != 0) {
        getAPI.post('tags/',{
          name: this.tag.toUpperCase(),
          name_en: this.tag.toUpperCase()
        })
        .then(response => {
          if(response.status == 201) {
            this.getAllTabs()
            let msg = 'Tag ajouté avec succès!'
            this.alertString = `${msg}|alert-yes|5000::`+Date.now()
            this.exitTagModule = Date.now()
          }
          else {
            let msg = 'Oups ! something went wrong.'
            this.alertString = `${msg}|alert-no|5000::`+Date.now()
          }
          this.exitTagModule = Date.now()
        })
        .catch(error => {
          if (error.response) {
            let firstError = Object.values(error.response.data)[0][0].replace(' en ', " ")
            this.alertString = `${firstError}|alert-no|10000::`+Date.now()
          }
          else if (error.request) {
            let msg = 'The request was made but no response was received. Please check your network.'
            this.alertString = `${msg}|alert-no|8000::`+Date.now()
          }
          else {
            let msg = 'Oups ! something went wrong.'
            this.alertString = `${msg}|alert-no|5000::`+Date.now()
          }
          this.exitTagModule = Date.now()
        })
      }
      else{
        let msg = 'Oups ! Veuillez remplir le champ convenablement.'
        this.alertString = `${msg}|alert-no|5000::`+Date.now()
        this.exitTagModule = Date.now()
      }
    },
    onImageLoaded (imageData) {
      this.module_image_cover = imageData
    },
    joinStringToArray (s1, s2, s3) {
      let array = []
      if(s1 != "")
        array.push(`${s1}`)
      if(s2 != "")
        array.push(`${s2}`)
      if(s3 != "")
        array.push(`${s3}`)
      return array;
    },
    addNewModule () {
      if(this.module_title != '' && this.module_category != '' && this.module_time != '' && this.module_image_cover != '' && this.module_description != '' && this.module_content != '' && this.module_video != '' && (
          this.module_tagSelect1 != '' || this.module_tagSelect2 != '' || this.module_tagSelect3 != ''
      )) {
        getAPI.post('modules/', {
          title: this.module_title,
          title_en: this.module_title,
          resume: this.module_description,
          resume_en: this.module_description,
          target: this.module_target,
          target_en: this.module_target,
          category: this.module_category,
          category_en: this.module_category,
          link: this.module_content,
          module_video: this.module_video,
          duration: this.module_time + " minutes",
          status: "unavailable",
          number: this.totalModule + 1,
          tags: this.joinStringToArray(this.module_tagSelect1, this.module_tagSelect2, this.module_tagSelect3),
        }).then((response) => {
          if(response.status == 201) {
                     const formData = new FormData();
            formData.append('module_image', this.module_image_cover);
            getAPI.patch(`modules/${response.data.data.id}/`, formData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            }).then(() => {
              this.getAllModule()
              let msg = 'Module ajouté avec succès!'
              this.alertString = `${msg}|alert-yes|5000::`+Date.now()
            })
          }
          this.exitModuleModal = Date.now()
        }).catch((error) => {
          if(error.response.status == 413) {
            this.alertString = `Oops ! Uploaded File Too Large|alert-no|10000::`+Date.now()
          }
          if (error.response) {
            let firstError = Object.values(error.response.data)[0][0].replace(' en ', " ")
            this.alertString = `${firstError}|alert-no|10000::`+Date.now()
          }
          else if (error.request) {
            let msg = 'The request was made but no response was received. Please check your network.'
            this.alertString = `${msg}|alert-no|8000::`+Date.now()
          }
          else {
            let msg = 'Oups ! something went wrong.'
            this.alertString = `${msg}|alert-no|5000::`+Date.now()
          }
          this.exitModuleModal = Date.now()
        });
      }
      else{
        this.alertString = `Formulaire invalide ou incomplet|alert-no|10000::`+Date.now()
        this.exitModuleModal = Date.now()
      }
    }
  },
  // created() {
  //   this.translate = new Translate();
  // },
  mounted () {
    this.getAllModule(),
    this.getAllTabs()
  }
}
</script>

<style scoped>
.tagUl{
  padding: 0;
}
.tagUl, .tagUl li{
  list-style-type: none;
}
.tag-bloc{
  min-height: 18em;
  width: calc(100% - 4em);
  background-color: red;
  /*background-color: #272f44;*/
  position: fixed;
  left: 2em;
  right: 2em;
  bottom: -14.5em;
}
.descriptionArea{
  height: 135px;
  width: 100%;
  position: relative;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 600;
  outline: currentcolor none medium !important;
  border: 1px solid rgb(204, 204, 204);
  box-shadow: rgba(0, 0, 0, 0.075) 0px 1px 1px inset;
  padding: 6px 12px;
  background: rgb(247, 248, 252) none repeat scroll 0% 0%;
}
.uploadImageArea{
  width: 100%;
  height: 20em;
  background-color: #d7dde3;
}
</style>
