<template>
    <div class="relative inline-block text-left">
      <button 
        @click="toggleDropdown"
        class="btn cfa-btn-primary btn spect-btn-accent"
      >
        Actions
      </button>
  
      <div 
        v-if="isOpen" 
        class="absolute right-0 mt-2 max-w-[300px] bg-white border rounded-md shadow-lg z-10"
      >
        <button 
          v-for="(action, index) in actions" 
          :key="index"
          @click="handleAction(action)"
          class="w-full whitespace-nowrap px-4 py-3 text-left hover:bg-gray-100"
        >
          <span class="mr-2 w-full text-nowrap ">{{ action.icon }}</span>
          {{ action.label }}
        </button>
      </div>
    </div>
</template>
  
  <script setup>
  import { ref, defineEmits, defineProps } from 'vue'
  
  defineProps({
    actions: {
      type: Array,
      required: true
    }
  })
  
  const emit = defineEmits(['action-selected'])
  
  const isOpen = ref(false)
  
  const toggleDropdown = () => {
    isOpen.value = !isOpen.value
  }
  
  const handleAction = (action) => {
    emit('action-selected', action.value)
    isOpen.value = false
  }
  </script>


<style lang="scss" scoped >
//@import "../../../assets/scss/tailwinds.scss";
</style>