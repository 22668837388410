<template>
  <div class="cfa-root">
    <c-f-a-nav :alert="alertString" contentClass="position-relative">
      <template v-slot:title>
        <div class="w-100 pt-5 text-start mt-2 mb-3 d-flex justify-content-between align-items-center">
          <h1 class="cfa-section-title">SOUSCRIPTION</h1>
          <button @click="newSubscription" class="btn cfa-btn-primary">
            CRÉER UNE SOUSCRIPTION <i class="fa fa-plus">&nbsp;</i>
          </button>
        </div>
      </template>
      <template v-slot:content>
        <div class="cfa-page pt-1">
          <!-- ********************** -->
          <Table :columns="plansColumns" :data="plans" :columns-action="plansToListen" @action="getCallToAction1"></Table>
          <!-- ********************** -->
          <br>
        </div>
      </template>
    </c-f-a-nav>
    <c-f-a-footer></c-f-a-footer>

    <!-- create -->
    <scalable-modal @callback="addNewSubscription" :break="exitAddPlanModal" display-mode="flex"  label="CRÉER UN PLAN" uid="cfa-modal-add-plan" @close="newPlan=false" :open="newPlan" :advancedControl="true" validationButtonText="Créer" exitButtonText="Annuler" :style="'width: 50em; height: auto; margin-top: 5em; margin-bottom: 2em; background-color: #f0f0f0;'">
      <template v-slot:content>
        <!-- blo1 -->
        <div v-show="waitingAPIResponse2" class="cfa-page">
          <div class="d-flex justify-content-center align-items-center" style="min-height: 70vh">
            <div class="preloader_center d-flex flex-column">
              <img src="../../assets/img/gif/rocket.gif" alt="cfa preloader">
              <p class="pl-3">loading . . .</p>
            </div>
          </div>
        </div>
        <div v-show="!waitingAPIResponse2" class=" w-100">
          <div class="w-100">
            <div class="form-group py-2 pe-0">
              <p class="mb-2"><strong>ORGANISATION</strong></p>
              <div class="d-flex flex-row row">
                <user-input @userSelected="getUserSelected" />
              </div>
            </div>
          </div>

          <div class="w-100 mt-3">
            <div class="form-group py-3 pe-0">
              <p class="mb-2"><strong>PLAN</strong></p>
              <custom-select :options="options" v-model="selectedOption" @input="getPlanSelected"></custom-select>
            </div>
          </div>
        </div>
      </template>
    </scalable-modal>
  </div>
</template>

<script>
import CFAFooter from '@/components/nav/CFAFooter.vue'
import CFANav from '@/components/nav/CFANav.vue'
import ScalableModal from '@/components/shared/modal/ScalableModal.vue'
import {getAPI} from "@/axios-api";
import Table from "@/components/shared/table/Table.vue";
import UserInput from "@/components/shared/input/UserInput.vue";
import CustomSelect from "@/components/shared/input/CustomSelect.vue";

export default {
  name: 'FacturationView',
  components: {
    CFAFooter,
    CFANav,
    Table,
    ScalableModal,
    UserInput,
    CustomSelect,
  },
  data () {
    return {
      alertString: null,
      newPlan: false,
      plans: [],
      plansColumns: ['n°', 'Nom', 'Prix', 'Durée', 'Types', 'Modules', 'Autres'],
      plansToListen: ['Autres'],

      openDeletionBox: null,
      tempDeleteID: null,
      tempDeleteName: '',

      waitingAPIResponse: true,
      waitingAPIResponse2: true,
      waitingAPIResponse3: true,
      selectedPlan: null,

      exitAddPlanModal: null,
      options: [],

      plan_name: '',
      plan_price: '',
      plan_duration: '',
      plan_type: '',
      plan_modules: [],
      plan_modules_update: [],

      callbackPayload: null,
      exitRoleModalDel: null,
      plan_deletion_check: null,
      openUpdatePerm: false,
      exitOrgUpdateModal: null,
      selectedOption: null,
      userSelected: null,
      planSelected: null,
    }
  },
  methods: {
    getUserSelected (payload) {
      this.userSelected = payload
    },
    getPlanSelected (payload) {
      this.planSelected = payload
    },
    getSubscriptions () {
      this.waitingAPIResponse = true
      getAPI.get(`subscriptions`)
          .then(response => {
            if(response.status == 200) {
              let plans = response.data;
              console.log(this.plans);
              this.plans = []
              for(let i=0; i<plans.length; i++) {
                let row = plans[i]
                let autres = `<div class="d-flex align-items-center">
                                <button btn-id="${row.id}" btn-action="view" class="btn cfa-btn-primary btn-action"><i class="fa fa-edit"></i>edit</button>
                                <button btn-id="${row.id}" btn-action="delete" class="ms-2 btn cfa-btn-danger btn-action"><i class="fa fa-trash">delete</i></button>
                           </div>`

                let modules = '<div class="text-start col-3 d-flex">';
                for (let j = 0; j < row.plan.modules.length; j++) {
                  modules += `<div class="item">Module ${row.plan.modules[j].split('')[0]}</div>`;
                }
                modules += '</div>'

                this.plans.push({'n°': (i+1),'Nom': row.plan.name, 'Prix': row.plan.price + ' XOF', 'Durée': row.plan.duration, 'Types': row.plan.type_plan, 'Modules': modules, 'Autres':autres, name: row.id})
              }
            }
            else {
              let msg = 'Oups ! something went wrong.'
              this.alertString = `${msg}|alert-no|5000::`+Date.now()
            }
            setTimeout(() => {
              this.waitingAPIResponse = false
            }, 2000);
          })
          .catch(error => {
            if (error.response) {
              this.alertString = `${error.response.data.message}|alert-no|10000::`+Date.now()
            }
            else if (error.request) {
              let msg = 'The request was made but no response was received. Please check your network.'
              this.alertString = `${msg}|alert-no|8000::`+Date.now()
            }
            else {
              let msg = 'Oups ! something went wrong.'
              this.alertString = `${msg}|alert-no|5000::`+Date.now()
            }
            this.waitingAPIResponse = false
          })
    },
    getCallToAction1 (payload) {
      console.log(payload)
      if(payload.action_type == 'delete') {
        this.openDeletionBox = true;
        this.tempDeleteID = payload.id;
        this.tempDeleteName = payload.name;
      }
      if(payload.action_type == 'view') {
        this.updateData(payload.id);
      }
    },
    updateData (id) {
      this.tempUpdateRoleId = id
      this.waitingAPIResponse2 = true
      this.openUpdatePerm = true
      getAPI.get(`subscriptions/${id}/`)
          .then(response => {
            if(response.status == 200) {
              this.selectedPlan = response.data;
            }
            else {
              let msg = 'Oups ! something went wrong.'
              this.alertString = `${msg}|alert-no|5000::`+Date.now()
            }
            setTimeout(() => {
              this.waitingAPIResponse2 = false
            }, 2000);
          })
          .catch(error => {
            if (error.response) {
              this.alertString = `${error.response.data.message}|alert-no|10000::`+Date.now()
            }
            else if (error.request) {
              let msg = 'The request was made but no response was received. Please check your network.'
              this.alertString = `${msg}|alert-no|8000::`+Date.now()
            }
            else {
              let msg = 'Oups ! something went wrong.'
              this.alertString = `${msg}|alert-no|5000::`+Date.now()
            }
            this.waitingAPIResponse2 = false
          })
    },
    getSelectedModules (payload) {
      console.log(payload)
      this.plan_modules = [];
      for (let i = 0; i < payload.length; i++) {
        this.plan_modules.push(payload[i].id)
        console.log(payload[i])
        console.log(this.plan_modules)
      }
    },
    getSelectedModulesUpdate (payload) {
      console.log(payload)
      this.plan_modules_update = [];
      for (let i = 0; i < payload.length; i++) {
        this.plan_modules_update.push(payload[i].id)
      }
      this.selectedPlan.modules = this.plan_modules_update
    },
    addNewSubscription () {
      getAPI.post('transactions/', {
        user: this.userSelected.id,
        plan: this.planSelected.id,
      })
      .then(response => {
        if(response.status == 201) {
          this.getSubscriptions()
          let msg = 'Souscription creéé avec succès!'
          this.alertString = `${msg}|alert-yes|5000::`+Date.now()
        }
        else {
          let msg = 'Oups ! something went wrong.'
          this.alertString = `${msg}|alert-no|5000::`+Date.now()
        }
        this.exitAddPlanModal = Date.now()
      })
      .catch(error => {
        if (error.response) {
          let firstError = Object.values(error.response.data)[0][0]
          this.alertString = `${firstError}|alert-no|10000::`+Date.now()
        }
        else if (error.request) {
          let msg = 'The request was made but no response was received. Please check your network.'
          this.alertString = `${msg}|alert-no|8000::`+Date.now()
        }
        else {
          let msg = 'Oups ! something went wrong.'
          this.alertString = `${msg}|alert-no|5000::`+Date.now()
        }
        this.exitAddPlanModal = Date.now()
      })
    },
    confirmDeletion () {
      if(!this.plan_deletion_check) {
        this.callbackPayload = 'Veuillez confirmer cette action.' + '::-1::' + Date.now();
      }
      else{
        getAPI.delete(`plans/${this.tempDeleteID}`)
            .then(response => {
              if(response.status == 204) {
                this.callbackPayload = 'Plan supprimé avec succès' + '::' + Date.now();
                setTimeout(() => {
                  this.getPlans()
                }, 1500)
              }
              else {
                let msg = 'Oups ! something went wrong.'
                this.alertString = `${msg}|alert-no|5000::`+Date.now()
                this.exitRoleModalDel = Date.now()
              }
            })
            .catch(() => {
              this.callbackPayload = 'Oups ! something went wrong.' + '::-1::' + Date.now();
            })
      }
    },
    confirmUpdate () {
      getAPI.patch(`plans/${this.tempUpdatePudId}/`, this.selectedPlan).then(response => {
        if(response.status == 200) {
          let msg = 'PLan MAJ avec succès!'
          this.alertString = `${msg}|alert-yes|5000::`+Date.now()
          this.exitOrgUpdateModal = Date.now()
          this.getPlans()
        }
        else {
          let msg = 'Oups ! something went wrong.'
          this.alertString = `${msg}|alert-no|5000::`+Date.now()
        }
        this.exitOrgUpdateModal = Date.now()
      })
          .catch(error => {
            if (error.response) {
              this.alertString = `${error.response.data[0]}|alert-no|10000::`+Date.now()
            }
            else if (error.request) {
              let msg = 'The request was made but no response was received. Please check your network.'
              this.alertString = `${msg}|alert-no|8000::`+Date.now()
            }
            else {
              let msg = 'Oups ! something went wrong.'
              this.alertString = `${msg}|alert-no|5000::`+Date.now()
            }
            this.exitOrgUpdateModal = Date.now()
          })
    },
    newSubscription () {
      this.newPlan=true;
      this.waitingAPIResponse2 = true
      getAPI.get(`plans/`)
          .then(response => {
            if(response.status == 200) {
              let plans = response.data.results;
              this.options = []
              for(let i=0; i<plans.length; i++) {
                this.options.push({'id': plans[i].id, 'label': plans[i].name})
              }
            }
            else {
              let msg = 'Oups ! something went wrong.'
              this.alertString = `${msg}|alert-no|5000::`+Date.now()
            }
            setTimeout(() => {
              this.waitingAPIResponse2 = false
            }, 2000);
          })
          .catch(error => {
            if (error.response) {
              this.alertString = `${error.response.data.message}|alert-no|10000::`+Date.now()
            }
            else if (error.request) {
              let msg = 'The request was made but no response was received. Please check your network.'
              this.alertString = `${msg}|alert-no|8000::`+Date.now()
            }
            else {
              let msg = 'Oups ! something went wrong.'
              this.alertString = `${msg}|alert-no|5000::`+Date.now()
            }
            this.waitingAPIResponse2 = false
          })
    }
  },
  mounted() {
    this.getSubscriptions()
  }
}
</script>

<style scoped>
.cfa-welcome{
  background-color: #fff;
  border-radius: 0.5em;
  padding: .5em 1em;
  box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);
  -webkit-box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);
  -moz-box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);
}
.cfa-certif-state-line:hover{
  /* box-shadow: 20px 12px 109px -37px rgba(0,0,0,0.87);
-webkit-box-shadow: 20px 12px 109px -37px rgba(0,0,0,0.87);
-moz-box-shadow: 20px 12px 109px -37px rgba(0,0,0,0.87); */
  box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);
  -webkit-box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);
  -moz-box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);
}
.cfa-progress-status{
  min-width: 7em;
}
.item {
  cursor: pointer;
  border: 1px solid #ccc;
  padding: 0.5em;
  margin: 0.5em;
  border-radius: 0.25em;
}
</style>
