<template>
  <div class="cfa-root">
    <c-f-a-nav :alert="alertString" contentClass="position-relative">
      <template v-slot:title>
        <div class="w-100 pt-5 text-start mt-2 mb-3 d-flex justify-content-between align-items-center">
          <h1 class="cfa-section-title">RESSOURCES</h1>
          <button @click="this.newModule=true" class="btn cfa-btn-primary">
            RESSOURCES <i class="fa fa-plus">&nbsp;</i>
          </button>
        </div>
      </template>
      <template v-slot:content>
        <div v-show="!waitingAPIResponse"  class="cfa-page">
          <div v-if="this.allResources.length == 0" class="d-flex justify-content-center align-items-center" style="min-height: 70vh">
            <div class="d-flex flex-column">
              <img src="@/assets/img/svg/cfa-ud-assets-05.svg" alt="empty content" style="width: 12em; margin-left: 2em;"/>
              <span class="mt-3 fs-5 fw-bold">Oups ! Rien pour le moment.</span>
            </div>
          </div>
          <div v-else class="row pt-3">
            <course-card2 v-for="src,i in allResources" v-bind:key="i" @update="getAllData" :preview="src.resources_image" :all-modules="allModules" :module="src.module" @sync="getAllData()" :id="src.id" :title="src.title" :description="src.description" :uid="i + '-cfa-resource'" :viewMode='displayMode' :filterMode="filterMode"  ></course-card2>
          </div>
        </div>
        <div v-show="waitingAPIResponse" class="cfa-page">
          <div class="d-flex justify-content-center align-items-center" style="min-height: 70vh">
            <div class="preloader_center d-flex flex-column">
              <img src="../../assets/img/gif/rocket.gif" alt="cfa preloader">
              <p class="pl-3">loading . . .</p>
            </div>
          </div>
        </div>
      </template>
    </c-f-a-nav>
    <c-f-a-footer></c-f-a-footer>
    <!-- create -->
    <scalable-modal @callback="addNewResource" :break="exitResourceModal" label="AJOUTER UNE RESSOURCE" uid="cfa-modal-src-add" @close="newModule=false" :open="newModule" :advancedControl="true" validationButtonText="Créer cette ressource" exitButtonText="Annuler" :style="'width: 50em; height: auto; margin-top: 2em; margin-bottom: 2em; background-color: #f0f0f0;'">
      <template v-slot:content>
        <!-- blo1 -->
        <div class="row w-100">
          <div class="form-group py-2 pe-0 pe-md-2 ">
              <p class="mb-2"><strong>TITRE</strong></p>
              <div class="d-flex flex-row">
                <input class="form-control" type="text" v-model="src_title" @input="limitInput">
              </div>
          </div>
          <div class="mt-2">
            <p class="mb-2"><strong>DESRIPTION</strong></p>
            <textarea class="descriptionArea" v-model="src_description"></textarea>
          </div>
          <div class="form-group py-2 pe-0 pe-md-2 ">
            <p class="mb-2"><strong>ATTACHED MODULE</strong></p>
            <select class="form-control" v-model="src_module">
              <option value="default" selected disabled hidden>Attach a tag</option>
              <option v-for="module in allModules" v-bind:key="module.id" :value="module.id">{{ module.title }}</option>
            </select>
          </div>
        </div>

        <div class="row w-100 mt-3">
          <div class="form-group py-3 pe-0 pe-md-2 ">
            <p class="mb-2"><strong>FICHIER PDF</strong></p>
            <document-input @pdf-loaded="onPdfLoaded"/>
          </div>
        </div>
      </template>
    </scalable-modal>
  </div>
</template>

<script>
import CFAFooter from '@/components/nav/CFAFooter.vue'
import CFANav from '@/components/nav/CFANav.vue'
import CourseCard2 from '@/components/shared/course/CourseCard2.vue'
import ScalableModal from '@/components/shared/modal/ScalableModal.vue'
import DocumentInput from '@/components/shared/input/DocumentInput.vue'
import {getAPI} from "@/axios-api";

export default {
  name: 'DashboardView',
  components: {
    CFAFooter,
    CFANav,
    CourseCard2,
    ScalableModal,
    DocumentInput
  },
  data () {
    return {
      displayMode: 'GROUP',
      filterMode: 'ALL',
      newModule: false,
      waitingAPIResponse: false,
      allResources: [],
      allModules: [],
      alertString: '',
      src_title: '',
      src_description: '',
      src_module: '',
      src_pdf: null,
      exitResourceModal: null,
    }
  },
  methods: {
    getViewMode (e) {
      this.displayMode = e
    },
    getViewFilter (e) {
      this.filterMode = e
    },
    async getAllData() {
      try {
        this.waitingAPIResponse = true;
        const [response1, response2] = await Promise.all([
          getAPI.get('modules/'),
          getAPI.get(`ressources`),
        ]);
        if(response1.status == 200 && response2.status == 200) {
          this.allModules = response1.data.results;
          this.allResources = response2.data.results;
          this.waitingAPIResponse = false
        }
        else{
          this.waitingAPIResponse = false
          this.alertString = `Une erreur est survenu|alert-no|4000::`+Date.now()
        }
      }
      catch (e) {
        this.alertString = `Une erreur est survenu|alert-no|4000::`+Date.now()
      }
    },
    onPdfLoaded (pdf_bin) {
      this.src_pdf = pdf_bin
    },
    addNewResource () {
      if(this.src_title != '' && this.src_description != '' && this.src_module != '' && this.src_pdf != null ) {

        const formData = new FormData();
        formData.append('title', this.src_title);
        formData.append('title_en', this.src_title);
        formData.append('description', this.src_description);
        formData.append('description_en', this.src_description);
        formData.append('module', this.src_module);
        formData.append('resources_link', this.src_pdf);

        getAPI.post('ressources/', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then((response) => {
          if(response.status == 201) {
            this.getAllData()
            let msg = 'Ressource ajouté avec succès!'
            this.alertString = `${msg}|alert-yes|5000::`+Date.now()
          }
          this.exitResourceModal = Date.now()
        }).catch((error) => {
          if (error.response) {
            let firstError = Object.values(error.response.data)[0][0].replace(' en ', " ")
            this.alertString = `${firstError}|alert-no|10000::`+Date.now()
          }
          else if (error.request) {
            let msg = 'The request was made but no response was received. Please check your network.'
            this.alertString = `${msg}|alert-no|8000::`+Date.now()
          }
          else {
            let msg = 'Oups ! something went wrong.'
            this.alertString = `${msg}|alert-no|5000::`+Date.now()
          }
          this.exitResourceModal = Date.now()
        });
      }
      else{
        this.alertString = `Formulaire invalide ou incomplet|alert-no|10000::`+Date.now()
        this.exitResourceModal = Date.now()
      }
    },
    limitInput() {
      if (this.src_description.length > 255) {
        this.src_description = this.src_description.slice(0, 255);
      }
    }
  },
  mounted() {
    this.getAllData()
  }
}
</script>

<style scoped>
.descriptionArea{
  height: 135px;
  width: 100%;
  position: relative;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 600;
  outline: currentcolor none medium !important;
  border: 1px solid rgb(204, 204, 204);
  box-shadow: rgba(0, 0, 0, 0.075) 0px 1px 1px inset;
  padding: 6px 12px;
  background: rgb(247, 248, 252) none repeat scroll 0% 0%;
}
</style>
