<template>
    <div class="relative" @clickaway="closeDropdown">
      <!-- Selected Items Container -->
      <div 
        class="w-full border rounded-md px-3 py-2 cursor-pointer flex flex-wrap gap-2 items-center"
        :class="[
          isOpen ? 'border-blue-500 ring-2 ring-blue-200' : 'border-gray-300',
          disabled ? 'bg-gray-100 cursor-not-allowed' : 'bg-white'
        ]"
        @click="toggleDropdown"
      >
        <!-- Selected Items -->
        <template v-if="selectedOptions.length">
          <span 
            v-for="item in selectedOptions.slice(0, maxSelectedLabels)"
            :key="item.id"
            class="bg-blue-100 text-blue-800 px-2 py-1 rounded-full text-xs flex items-center"
          >
            {{ item.name }}
            <button 
              @click.stop="removeOption(item)"
              class="ml-1 hover:text-red-600"
            >
              &times;
            </button>
          </span>
          <span 
            v-if="selectedOptions.length > maxSelectedLabels" 
            class="text-gray-500 text-xs"
          >
            +{{ selectedOptions.length - maxSelectedLabels }} more
          </span>
        </template>
        
        <!-- Placeholder -->
        <span 
          v-else 
          class="text-gray-500"
        >
          {{ placeholder }}
        </span>
      </div>
  
      <!-- Dropdown -->
      <transition 
        enter-active-class="transition duration-200 ease-out"
        enter-from-class="scale-95 opacity-0"
        enter-to-class="scale-100 opacity-100"
        leave-active-class="transition duration-150 ease-in"
        leave-from-class="scale-100 opacity-100"
        leave-to-class="scale-95 opacity-0"
      >
        <div 
          v-if="isOpen" 
          class="absolute z-10 w-full mb-3 bg-white border border-gray-300 rounded-md shadow-lg max-h-60 overflow-y-auto bottom-3/4 "
        >
          <!-- Search Input -->
          <div class="p-2">
            <input 
              type="text" 
              v-model="searchTerm"
              placeholder="Search..." 
              class="w-full px-2 py-1 border rounded text-sm focus:outline-none focus:ring-2 focus:ring-blue-200"
            >
          </div>
  
          <!-- Options List -->
          <ul class="py-1">
            <li 
              v-for="option in filteredOptions" 
              :key="option.id"
              class="px-3 py-2 hover:bg-gray-100 cursor-pointer flex items-center"
              :class="{'bg-blue-50': isSelected(option)}"
              @click="toggleOption(option)"
            >
              <input 
                type="checkbox" 
                :checked="isSelected(option)"
                class="mr-2 text-blue-600 focus:ring-blue-500 rounded"
              >
              {{ option.name }}
            </li>
            <li 
              v-if="filteredOptions.length === 0" 
              class="px-3 py-2 text-gray-500 text-center"
            >
              No options found
            </li>
          </ul>
        </div>
      </transition>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      modelValue: {
        type: Array,
        default: () => []
      },
      options: {
        type: Array,
        required: true
      },
      placeholder: {
        type: String,
        default: 'Select options'
      },
      maxSelectedLabels: {
        type: Number,
        default: 6
      },
      disabled: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        isOpen: false,
        searchTerm: ''
      }
    },
    computed: {
      selectedOptions() {
        return this.options.filter(option => 
          this.modelValue.includes(option.id)
        )
      },
      filteredOptions() {
        return this.options.filter(option => 
          option.name.toLowerCase().includes(this.searchTerm.toLowerCase()) &&
          !this.modelValue.includes(option.id)
        )
      }
    },
    methods: {
      toggleDropdown() {
        if (!this.disabled) {
          this.isOpen = !this.isOpen
        }
      },
      closeDropdown() {
        this.isOpen = false
        this.searchTerm = ''
      },
      toggleOption(option) {
        const currentValue = [...this.modelValue]
        const index = currentValue.findIndex(id => id === option.id)
        
        if (index > -1) {
          currentValue.splice(index, 1)
        } else {
          currentValue.push(option.id)
        }
        
        //this.$emit('input', currentValue)
        console.log(currentValue)
        this.$emit('update:modelValue', currentValue);
      },
      removeOption(option) {
        const currentValue = this.modelValue.filter(id => id !== option.id)
        //this.$emit('input', currentValue)
        this.$emit('update:modelValue', currentValue);
      },
      isSelected(option) {
        //this.toggleOption(option)
        return this.modelValue.includes(option.id)
      }
    }
  }
  </script>

<style lang="scss" scoped >
 //@import "../../../assets/scss/tailwinds.scss";
</style>